import { Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { RestoreComponent } from './auth/restore/restore.component';
import { AuthGuard } from './auth/_guards/auth.guard';
import { PdfViewComponent } from "./pdf-view/pdf-view.component";
import { TitleRouterResolver } from "./title-router-resolver";
import { MedicalTreatingReportPageComponent } from "./@view/medical-treating-report-page/medical-treating-report-page.component";
import { ClaimantPrintRecordPageComponent } from './@view/claimant-print-record-page/claimant-print-record-page.component';
const ɵ0 = { animation: 'Login' }, ɵ1 = () => import("./@view/reports/reports.module.ngfactory")
    .then(m => m.ReportsModuleNgFactory), ɵ2 = () => import("./@view/view.module.ngfactory").then(m => m.ViewModuleNgFactory), ɵ3 = { animation: 'Home' };
const appRoutes = [
    {
        path: 'login', component: LoginComponent, data: ɵ0
    },
    {
        path: 'restore/:email/:hash', component: RestoreComponent
    },
    {
        path: 'report', loadChildren: ɵ1, canActivate: [AuthGuard]
    },
    {
        path: 'view-report', component: PdfViewComponent
    },
    {
        path: 'view-medical-treating-report', component: MedicalTreatingReportPageComponent
    },
    {
        path: 'view-print-claimant', component: ClaimantPrintRecordPageComponent
    },
    {
        path: '', loadChildren: ɵ2,
        canActivate: [AuthGuard], data: ɵ3, resolve: { test: TitleRouterResolver }
    },
    { path: '**', redirectTo: '', pathMatch: 'full' },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3 };
